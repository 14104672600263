.App {
    .InputWraper {
        > .label {
            color: $white;
        }

        > .description {
            color: $grey;
        }

        > .wraper {
            > .input {
                input {
                    color: $white;
                    background: transparent;
                }
            }
        }

        &.disabled {
            > .wraper {
                .input {
                    border-color: transparent !important;
                    background: $offDark;

                    input {
                        color: $white;
                    }

                    &:after {
                        cursor: default;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba($color: #000000, $alpha: 0.1);
                        border-radius: $borderRadius;
                    }
                }
            }
        }
    }

    // ============================ Table style ================================
    .Table {
        min-height: 150px;
        background-color: unset;
        &.fixLayout {
            table {
                table-layout: fixed;
            }
        }

        table {
            border-collapse: unset;
            border-spacing: 0px;
            thead {
                tr {
                    // border-bottom: 1px solid $offDark;
                }
            }

            tbody {
                background: $dark;
                box-shadow: none;
                border-radius: 0;
                // border-bottom: 1px solid $offDark;
            }

            td {
                color: $white;
            }

            tr {
                &.filter {
                    th {
                        padding-top: 10px;
                    }
                }
            }
        }

        .Message {
            background: transparent !important;
            box-shadow: none;
            border-radius: 0;
        }

        .fetching {
            background: transparent !important;
            border-radius: 0;

            svg.spinner {
                circle {
                    stroke: $white;
                }
            }
        }

        &.highLightHeader {
            thead th {
                color: $primary;
            }
        }

        &.Custom {
            .Table__FilterBox {
                border-radius: $borderRadius;
                border: unset;
                transition: 0.3s max-height, 0.4s padding, 0.3s opacity;
                max-height: 200px;
                opacity: 1;
                &.hideFilter {
                    overflow: hidden;
                    max-height: 0px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    opacity: 0;
                }
            }

            .Table__FilterBox,
            thead,
            tbody,
            tfoot {
                background-color: $boxBgColor;
            }
            table thead th:first-child {
                border-radius: $borderRadius 0 0 $borderRadius;
            }

            table thead th:last-child {
                border-radius: 0 $borderRadius $borderRadius 0;
            }

            tfoot td {
                border-radius: $borderRadius;
            }
            thead th {
                font-size: 1.3em;
                font-weight: 600;
            }
            .Footer {
                padding: 0;
            }

            .fakeLine {
                padding: 5px;
                background: #222b45;
            }
            .footerPadding {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        &.textCenter {
            th,
            td {
                text-align: center;
            }
        }

        .pagination {
            list-style-type: none;
            display: flex;
            .pageNumber {
                width: 30px;
                cursor: pointer;
                a {
                    text-decoration: none;
                    color: inherit;
                }
                &:hover,
                &.active {
                    color: $primary;
                }
            }
        }
    }

    .Table.scroll {
        background: $dark;

        table {
            thead.Table__Head {
                tr {
                    th {
                        background: $dark;
                    }
                }
            }

            thead.Table__Filter {
                tr {
                    td {
                        background: $dark;
                    }
                }
            }
        }
    }
    // ============================ End Table style ============================

    // ============================ Alert style ================================
    &.Mobile {
        .Alert {
            padding: 0;
            max-width: 100%;
            width: calc(100% - 30px);

            .Alert__Item {
                margin-top: 15px;

                .icon {
                    width: max-content;
                    padding: 10px;

                    svg {
                        height: 25px;
                    }
                }

                .content {
                    padding: 10px 0;
                    padding-right: 15px;

                    .title {
                        font-size: 1em;
                        font-weight: 700;
                        margin-bottom: 0;
                    }

                    .message {
                        font-size: 1em;
                    }
                }
            }
        }
    }
    // ============================ End Alert style ============================

    // ============================ Button style ================================
    .Button {
        &.offDark-outline {
            @include btnOutlineColor($offDark);
        }

        &.grey-outline {
            @include btnOutlineColor($grey);
        }
    }
    // ============================ End Button style ============================

    // ============================ Message style ================================
    .Mobile {
        .Message {
            .content {
                word-break: break-word;
            }
        }
    }
    // ============================ End Message style ============================
}
