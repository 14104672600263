@import "../../../module.variables.scss";

.PaymentList {
    .group {
        display: flex;
        align-items: center;

        @for $i from 20 to 300 {
            .wd#{$i} {
                width: $i + unquote("px") !important;
            }
        }
    }

    .btnGroup {
        svg {
            width: 16px;
            fill: #fff;
        }

        .btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            cursor: pointer;

            &__hover:hover {
                svg {
                    fill: $grey;
                }
            }
        }
    }
}
