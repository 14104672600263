@import "../../../../module.variables.scss";

.Edit {
    min-width: 400px;
    .group {
        display: flex;
        justify-content: space-between;
        .inputTextRight {
            .wraper {
                .input {
                    input {
                        text-align: right;
                    }
                }
            }
        }
        .numberCenter {
            .wraper {
                .input {
                    input {
                        text-align: center;
                    }
                }
            }
        }
    }
    .chkRegister {
        display: inline-flex;
        justify-content: start;
        .checkbox {
            margin-right: 10px;
        }
        .label {
            .input {
                width: 80px;
                text-align: right;
            }
        }
    }
    .textBoldItalic {
        font-weight: bold;
        font-style: italic;
        padding: 10px 0;
    }
    .itemSelect {
        margin-bottom: 20px;
        .label {
            color: white;
        }
    }
    .groupButton { 
        text-align: right;
    }
}
