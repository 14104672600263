@import "../../../module.variables.scss";

.RolePermission {
    .group {
        justify-content:normal;

        .label {
            align-self: center;
        }

        .InputSelect {
            max-width: 300px;
        }
    }

    .gridContainer {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        grid-template-columns: auto 120px auto;
        grid-gap: 50px;
        
        .iconLeftRight {
            align-self: center;
            text-align: center;
            margin-top: 50px;

            .MuiBox-root {
                cursor: pointer;
                color: blue;

                &:hover {
                    color: $primary;
                }
            }
        }

        .tablePermission {            
            .title {
                padding: 10px 0;
            }

            .content {
                color: $white;
                background-color: rgb(18, 110, 110);
                height: 50vh;
                overflow: auto;
                padding: 10px;

                .hover:hover {
                    background-color: $grey;
                }
            }
        }
    }

    .InputCheckbox {
        margin-bottom: 0;
    }
}