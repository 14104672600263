@import "../../../module.variables.scss";

.UserKYCList {
    .group {
        display: flex;
        justify-content: unset;

        .btnGroup {
            align-self: center;
        }

        .InputWraper {
            .wraper {
                textarea {
                    width: 350px;
                }
            }
        }
    }

    .groupButton {
        text-align: right;
    }

    .widthSearch {
        width: 40%;
    }

    .btnGroup {
        svg {
            width: 16px;
            fill: #fff;
        }

        .btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .__hover:hover {
        opacity: 0.6;
    }
}
