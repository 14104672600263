@import "../../module.variables.scss";

.PopupWraper {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    width: 100%;
    height: 100%;
    z-index: 800;
    overflow-y: auto;

    display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // padding-bottom: 150px;

    .box {
        position: relative;
        // top: 30px;
        // left: 50%;
        // transform: translateX(-50%);
        width: max-content;
        max-width: calc(100% - 30px);
        background: $offDark;
        border-radius: $borderRadius;

        .boxTitle {
            background: $dark;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;

            span {
                font-size: 1.3em;
            }

            .btnClose {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                opacity: 0.8;
                cursor: pointer;

                svg {
                    height: 15px;

                    * {
                        fill: $white;
                    }
                }

                &:hover {
                    opacity: 1;
                }
            }
        }

        .content {
            padding: 15px;
        }
    }

    &--center {
        .box {
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            margin: auto;
        }
    }
}
