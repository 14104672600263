@import "../../../module.variables.scss";

.InputNumberRange {
    display: inline-flex;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    max-width: 400px;

    .input {
        display: inline-flex;
        .label {
            width: 50px;
            align-self: center;
            text-align: center;
        }

        input {
            border: none;
            outline: none;
            background: transparent;
            height: $inputHeight;
            border-left: 1px solid $borderColor;
            border-right: 1px solid $borderColor;
            color: $white;
            max-width: 150px;
            padding: 5px;
            text-align: right;
        }
    }
}