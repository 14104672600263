@import "../../../../module.variables.scss";

.Edit {
    min-width: 400px;
    .group {
        display: flex;
        justify-content: space-between;
    }
    .label {
        color: white;
    }
    .image {
        margin-top: 20px;
    }
    .title {
        border-radius: 5px;
        background-color: white;
    }
    .btnCreateUpdate {
        margin-top: 20px;
    }
}
