@import "../../../module.variables.scss";

.UserInfo {
    .groupButton {
        text-align: right;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 50px;
        
        div {
          padding: 5px 0;
        } 
      }
}
