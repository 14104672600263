@import "../../../../module.variables.scss";

.EditUser {
    min-width: 400px;
    .group {
        display: flex;
        justify-content: space-between;
    }
    .widthPhone {
        width: 250%;
    }
}
