@import "../../../module.variables.scss";

.PageSettingNoti {
    &__Head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__Title {
            font-size: 1.8em;
            font-weight: 600;
            color: $white;
        }
    }

    &__Body {
        .switch {
            width: 50px;
            height: 25px;

            .slider:before {
                height: 19px;
                width: 19px;
            }
            input:checked + .slider {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }
}
