@import "../../../module.variables.scss";

.TrangdingDetail {
    .gridContainer {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 50px;
    }

    .btnGroup {
        text-align: right;
    }

    .feedback {
        white-space: pre-line;
    }
}
