@import "../../../../module.variables.scss";

.Info {
    min-width: 400px;
    .group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-bottom: 8px;
        label {
            color: $primary;
        }

        .switch {
            width: 50px;
            height: 25px;

            .slider:before {
                height: 19px;
                width: 19px;
            }
        }
    }
    .btnBan {
        background-color: #d99e0b;
        border: 1px solid #d99e0b;
    }
}
