@import "../../../../module.variables.scss";

.Edit {
    min-width: 400px;
    .group {
        display: flex;
        justify-content: space-between;
    }
    .wfull {
        .label {
            margin-top: 2px;
            color: white;
        }
    }
}
